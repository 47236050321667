body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-dark .nav .nav-item .nav-link {
  color: #fff;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

div > .container-fluid {
  padding-top: 50px;
  padding-right: 50px;
  padding-left: 50px;
}

.clickable-column:hover {
  cursor: pointer;
}

#pulse-check-queue-table {
  table-layout: auto;
}

.fa:hover {
  cursor: pointer;
}

.fa-check-circle:hover {
  color: #8aa354;
}

.fa-minus-circle:hover {
  color: #e0a800;
}

.fa-exclamation-circle:hover {
  color: #a30000;
}
.App {
  text-align: center;
}

.required-date-column, .completion-date-column {
  min-width: 125px;
}

.Pulse-check-data-table-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Pulse-check-data-table-left-div {
  margin-right: 2rem;
}

.Pulse-check-data-table-left-div, .Pulse-check-data-table-right-div {
  width: 48%;
}

.Data-container {
  display: flex;
  flex-direction: column;
}

.Page-two-container-row {
  display: flex;
  flex-direction: row;
}

.Page-two-container-tables {
  width: 60%;
}

.Page-two-container-pictures {
  width: 40%
}

.Page-two-title {
  text-align: left;
  margin-bottom: 5em;
}

.Page-two-top {
  display: flex;
}

.Page-two-table {
  border-collapse: collapse;
  margin-bottom: 2rem;
  width: 100%;
  table-layout: fixed;
  font-size: 87.5%;
  outline: 1px solid #000;
}

.Page-two-table th, .Page-two-table td:not(.Bearing-table-td) {
  outline: .5px solid #000;
  padding: 0.1rem;
  text-align: left;
  padding-left: .25rem;
  word-wrap: break-word;
}

.Page-two-table th {
  background-color: #007bff;
  color: #fff;
  font-weight: 500;
}

.Bearing-table {
  table-layout: auto;
}

.Bearing-table-td {
  width: 66%;
  padding: 0;
}

.Bearing-table-td td {
  min-width: 5em;
  max-width: 5em;
}

.Bearing-table-td table{
  width: 100%;
}

#Bearing-table-item-title  {
  text-align: center;
}

.Temperature-data {
  width: 66%;
}

#Temperature-date {
  text-align: center;
}

.Threshold-vertical-table-title {
  white-space: nowrap;
  width: 40%;
}

.Threshold-vertical-table-td {
  width: 15%;
}

#Active-threshold-table {
  table-layout: auto;
  table-layout: initial;
}

.asset-image-container {
  margin: 0 auto;
  display: flex;
  flex-basis: row;
  justify-content: center;
}

.asset-image-container img {
  max-width: 75%;
  min-width: 200px;
  min-height: 200px;
  border: 1px solid #dee2e6;
  color: #dee2e6;
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
}

#Asset-picture-div, #Name-plate-picture-div {
  width: 50%;
}

#Asset-picture img, #Name-plate-picture img {
  height: auto;
  padding: 10px;
}

#assetPictureModal, #nameplatePictureModal {
  height: auto;
  width: 100%;
}

.Page-two-button-container {
  display: flex;
  justify-content: flex-end;
}

.Page-two-analyst-title {
  border-bottom: 2px solid #000;
  text-align: left;
  padding: 25px;
  margin: 10px 0px 20px
}

.Page-two-button-container button, .Save-report-btn button {
  margin: 10px;
  padding: 5px 15px;
}

.Chart-div {
  max-width: 100%;
}

.Report-page {
  margin-bottom: 3em;
}

.Report-page-title h3 {
  color: #000;
  font-size: 3em;
  padding-left: .25em;
}

.Report-page-text {
  text-align: left;
}

.Report-asset-information, .Report-page-text-summary,
.Report-recommendations, .Report-asset-status-container, .Static-report-statuses,
.Report-page-text-editor {
  margin: 10px;
}

.Report-data {
  margin: 0 auto;
  text-align: left;
}

.Report-data p {
  margin: 0;
  line-height: 20px;
}

.Report-data-title {
  color: darkgrey;
  padding-right: 25px;
}

.Report-asset-status-container {
  margin-left: 4em;
}

.Report-asset-status-container td {
  vertical-align: middle;
  padding: 0 .5em;
}

.Report-asset-status-container td:not(.Report-status-text) {
  width: 25%;
}

.Report-status-text, .Static-report-statuses {
  min-width: 50%;
}

.Report-recommendations {
  text-align: left;
}

/* temporary */
.Report-page-text-editor {
  height: 250px;
}

.Static-report-statuses-table {
  margin-top: 2em;
  text-align: left;
}

.Static-report-statuses-table tr {
 padding: 1em;
}

.Static-report-statuses-table td {
  padding-left: 1em;
}

.Static-report-statuses-table .fa, .Recommendation-title .fa {
  cursor: default;
}

.Static-report-statuses-table .fa-check-circle, .Recommendation-title .fa-check-circle {
  color: #9DBB61;
}

.Static-report-statuses-table .fa-minus-circle, .Recommendation-title .fa-minus-circle{
  color: #FFC000;
}

.Static-report-statuses-table .fa-exclamation-circle, .Recommendation-title .fa-exclamation-circle {
  color: #C00000;
}

.Pulse-check-id {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Report-page-title {
  display: flex;
  width: 100%;
  text-align: left;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
  white-space: nowrap;
}

@media screen and (max-width: 740px) {
  .Report-page-title h4 {
    white-space: normal;
    flex-shrink: 0.5;
  }  
}

.Report-line {
  width: 100%;
  height: 2px;
  background: #000;
  margin: 0 10px;
  flex-shrink: 1;
}

.format-toolbar {
  display: flex;
  padding: 10px 0;
}

.format-toolbar button {
  margin-right: 3px;
}

.text-editor {
  height: 100%;
  text-align: left;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #000;
  border-radius: .25rem;
  overflow-y: scroll;
}

.Save-report-btn {
  float: right;
  margin: 2em 0 1em;
}

.Save-failed-div {
  min-height: 70px;
  max-height: 70px;
}

.btn.focus, .btn:focus, .btn-secondary.focus, .btn-secondary:focus, .btn-secondary.focus, .btn-secondary:focus {
  box-shadow: none!important;
  -webkit-transition: none;
  transition: none;
  outline: 0!important;
}

.Static-summary-div {
  margin-bottom: 1.15em;
}

@media screen and (max-width: 740px) {
  .Report-page-title h3 {
    font-size: 2em;
  }

  .Static-report-statuses-table {
    margin-left: 0;
    margin-top: 0;
  }

  .Static-report-statuses-table td {
    padding: 0;
    border-bottom: 1px solid lightgrey;
    vertical-align: center;
    padding: .25em;
  }
}

#Close-alert-div-failed {
  position: absolute;
  right: 2%;
  margin-top: 1em;
}

#Close-alert-div-success, #Cancel-alert-div-success, #Cancel-alert-div-failed {
  position: absolute;
  right: 3%;
  margin-top: 1em;
}

.Fail-chart-div {
  padding: 2em 2em 1.5em;
  border: 1px solid #000;
}

.Previous-pulse-check-table {
  margin: 36px auto;
  width: 80%;
}

.Previous-pulse-check-id-button {
  padding: 0;
}

#Download-alert-div-failed {
  position: absolute;
  right: 3%;
  margin-top: 4em;
}

.Advertisement {
  margin: 0 0 2em 0;
  max-width: 18em;
  display: flex;
  flex-direction: column;
}

.Advertisement-image {
  height: auto;
  margin-bottom: 1.5em;
  margin-left: 1.0em;
  margin-top: 0em;
  width: 100%;
}

.Advertisement-label {
  background-color: #485A64;
  width: 100%;
  padding: .5em 0;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1em;
  margin-left: 1.0em;
  margin-top: 0em;
  display: block;
  word-wrap: break-word;
}

.Save-animation-div {
  margin: 5px 10px;
  padding: 0 70px 0 50px;
  text-align: center;
  position: relative;
  bottom: 10px;
}

.save-animation {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
}

button.save-animation {
  -webkit-animation: saveAnimation 450ms ease forwards;
          animation: saveAnimation 450ms ease forwards;
}

@-webkit-keyframes saveAnimation {
  0% { width: 200px; }
  100% { width: 0px; background: #fff;}
}

@keyframes saveAnimation {
  0% { width: 200px; }
  100% { width: 0px; background: #fff;}
}

button.save-animation::after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #0066ff;
  border-color: #0066ff transparent #0066ff transparent;
  -webkit-animation: save-animation 1.2s linear infinite;
          animation: save-animation 1.2s linear infinite;
  position: relative;
  right: 10px;
}

@-webkit-keyframes save-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes save-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Pulse-check-table-div td {
  cursor: pointer;
}

.Recommendation-title {
  display: flex;
}

.recommendation-icon {
  margin-right: 10px;
}

.Recommendation-title h5 {
  margin-top: 2px;
}

.Recommendation-title > div > ol {
  padding-left: 1.0em;
  margin-bottom: 0.5em;
}

.Asset-type-column, .Analyst-column {
  width: 150px;
}

.Pulse-check-table-div th button {
  background-color: #fff;
  color: #333;
  border-color: #dee2e6;
}

.Pagination-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 20px;
}

.Id-column {
  width: 75px;
  max-width: 75px;
}

.Previous-pulse-check-column {
  width: 90px;
  max-width: 90px;
}

.Status-column, .Country-column, .Industry-column, .Asset-name-column, .Asset-tag-column {
  width: 150px;
  max-width: 150px;
}

.Required-date-column, .Completed-date-column {
  width: 170px;
  max-width: 170px;
}

.Industry-column, .Application-column, .Company-column, .Asset-type-column {
  width: 175px;
  max-width: 175px;
}

.Analyst-column {
  width: 200px;
}

.Dropdown-scroll {
  max-height: 700px;
  overflow-y: scroll;
}

.Sorting-icon {
  width: 7px;
  cursor: pointer;
}

.ascArrow, .descArrow {
  color: #0066ff;
}

.Sorting-button {
  font-weight: 700;
  border: none;
}

.Sorting-button:hover {
  color: #000;
}

.comment-container {
  text-align: left;
  padding-left: 2em;
}
